$(function(){
    filterNews();
});

function filterNews() {
    var $filterBtn = $('[data-filter-id]');
    $filterBtn.on('click', function(e) {
        e.preventDefault();
        var $self = $(this),
            id = $self.data('filter-id'),
            page = $self.data('filter-page'),
            $showMoreBtn = $('[data-show-more]'),
            url = $showMoreBtn.data('url'),
            $contentBox = $('[data-load-content]'),
            data = {
                id: id,
                PAGEN_1: page,
                ajax: 'filter_news'
            };
        $filterBtn.removeClass('active-filter');
        $self.addClass('active-filter');
        if ($self.hasClass('sending')) return false;
        $self.addClass('sending');
        $self.addClass('disabled');

        $.nx.insertPreloader($contentBox, false, 'btn');

        $.ajax({
            type: "POST",
            url: url,
            data: data,
            success: (res) => {
                $self[0].classList.remove('sending');
                let resp = JSON.parse(res);
                $contentBox.html(resp.html);

                $.nx.hidePreloader($contentBox);
                $self[0].classList.remove('disabled');
                $('[data-show-more]')[0].dataset.showMore = 2;
                if (resp.isEnd) {
                    $('[data-show-more]').addClass('hidden');
                } else {
                    $('[data-show-more]').removeClass('hidden');
                }
            },
            error: (qXHR, textStatus, errorThrown) => {
                $self[0].removeClass('sending');
                $.nx.hidePreloader($contentBox);
                console.log(qXHR, textStatus, errorThrown);
            }
        });
    });
}