$(function () {
    checkWindowSize();
    $(window).on('resize orientationchange', () => {
        checkWindowSize();
    });
});
function checkWindowSize() {
    let $bg = $('[data-bg-xs]'),
        windowWidth = $(window).width();
    if(windowWidth < 769) {
        $bg.each((i,el) => {
            $(el).css('background-image', `url('${$(el).data('bg-xs')}')`);
            $(el).css('background-size', 'cover');
        });
    } else {
        $bg.each((i,el) => {
            $(el).css('background-image', `url(${$(el).data('bg-md')}')`);
            $(el).css('background-size', 'cover');
        });
    }
}