"use strict";
/**
 * Слайдер с несколькими элементами
 */

import Swiper from 'swiper';

class ListSlider {
    constructor() {
        this.dataName = {
            listSlider: 'list-slider',
            listSliderNext: 'list-slider-next',
            listSliderPrev: 'list-slider-prev',
            dots: 'list-dots',
            progress: 'list-progress'
        };

        this.bulletTemplate = '<span class="swiper-pagination-bullet nx-list-slider__bullet"></span>';

        this.init()
    }

    init(){
        let $listSlider = document.querySelectorAll(`[data-${this.dataName.listSlider}]`);

        if (!$listSlider.length) return false;

        $listSlider.forEach((el) => {
            let $self = el,
                sliderType = el.getAttribute('data-list-slider'),
                $next = $self.querySelector(`[data-${this.dataName.listSliderNext}]`),
                $prev = $self.querySelector(`[data-${this.dataName.listSliderPrev}]`),
                $progressbar = $self.querySelector(`[data-${this.dataName.progress}]`);

            switch (sliderType) {

                case 'clients':

                    let clientsSlider = new Swiper(el, {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 40,
                        loop: true,
                        loopFillGroupWithBlank: true,
                        speed: 500,
                        autoHeight: false,
                        navigation: {
                            nextEl: $next,
                            prevEl: $prev,
                        },
                        breakpoints: {
                            // when window width is >=
                            768: {
                                slidesPerView: 4,
                                slidesPerGroup: 4,
                                spaceBetween: 20
                            },
                            991: {
                                slidesPerView: 5,
                                slidesPerGroup: 5,
                                spaceBetween: 30
                            },
                        },
                        autoplay: {
                            delay: 5000,
                            disableOnInteraction: false,
                        },
                    });
                    clientsSlider.on('slideChange', function () {
                        if (!$progressbar) return;
                        if ($progressbar.classList.contains('active')) $progressbar.classList.remove('active')
                        if (!$progressbar.classList.contains('active')) setTimeout(() => $progressbar.classList.add('active'), 100)
                    });
                    break;

                case 'review':

                    let reviewSlider = new Swiper(el, {
                        slidesPerView: 1,
                        spaceBetween: 40,
                        loop: true,
                        speed: 500,
                        autoHeight: false,
                        navigation: {
                            nextEl: $next,
                            prevEl: $prev,
                        },
                        autoplay: {
                            delay: 5000,
                            disableOnInteraction: false,
                        },
                    });
                    reviewSlider.on('slideChange', function () {
                        if ($progressbar.classList.contains('active')) $progressbar.classList.remove('active')
                        if (!$progressbar.classList.contains('active')) setTimeout(() => $progressbar.classList.add('active'), 100)
                    });
                    break;
            }
        });
    }
}

export default ListSlider;