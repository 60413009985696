$(function () {
    initDragger();

    $('.js-hor-scroll').each(function () {
        checkScrollArrow($(this));
    });

    $(window).resize(function () {
        $('.js-hor-scroll').each(function () {
            checkScrollArrow($(this));
        })
    });

});
(function ($) {
    $.fn.attachDragger = function () {
        var attachment = false, lastPosition, position, difference, scrollBlock = $(this);

        $(this).on("mousedown mouseup mousemove touchmove touchstart touchend click", function (e) {
            var $box = $(this);

            if (e.type == "mousedown") {
                attachment = true;
                lastPosition = [e.clientX, e.clientY];
            }
            if (e.type == "touchstart") {
                attachment = true;
                lastPosition = [e.touches[0].clientX, e.touches[0].clientY];
            }
            if (e.type == "mouseup" || e.type == "touchend") attachment = false;
            if ((e.type == "mousemove" || e.type == "touchmove") && attachment == true) {

                if (e.type == "mousemove") position = [e.clientX, e.clientY];
                if (e.type == "touchmove") position = [e.touches[0].clientX, e.touches[0].clientY];

                difference = [(position[0] - lastPosition[0]), (position[1] - lastPosition[1])];
                $box.scrollLeft($box.scrollLeft() - difference[0]);
                $box.scrollTop($box.scrollTop() - difference[1]);
                if (e.type == "mousemove") lastPosition = [e.clientX, e.clientY];
                if (e.type == "touchmove") lastPosition = [e.touches[0].clientX, e.touches[0].clientY];
            }
            if ($(window).width() > 960) {
                if (position && position[0] === lastPosition[0] && position[1] === lastPosition[1]) e.preventDefault();
            }

            if ($(this).hasClass('js-hor-scroll--arrow')) {
                var $boxArrow = $box.closest('.js-arrow-container').find('.js-pf-filter__arrow'),
                    $boxContent = $box.find('.js-pf-filter__list');
                if (Math.ceil($box.scrollLeft() + $box.width()) < Math.floor($boxContent.width())) {
                    $boxArrow.addClass('active');
                } else {
                    $boxArrow.removeClass('active');
                }
            }

        });
        $(document).on("mouseup", function () {
            attachment = false;
        });
    };
})(jQuery);

function checkScrollArrow(block) {
    if (block.hasClass('js-hor-scroll--arrow')) {
        var $arrowContainer = block.closest('.js-arrow-container'),
            $boxContent = $arrowContainer.find('.js-pf-filter__list'),
            $boxArrow = $arrowContainer.find('.js-pf-filter__arrow');

        if (Math.ceil($boxContent.width()) > Math.ceil(block.width())) {
            $boxArrow.addClass('active');
        } else {
            $boxArrow.removeClass('active');
        }
    }
}

function initDragger() {
    var $horScroll = $('.js-hor-scroll');
    if (!$horScroll.length) return false;
    $horScroll.attachDragger();
}