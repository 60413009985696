$(function(){
    initAutosize();
});

function initAutosize() {
    var $textarea = $('[data-dynamic-textarea]');
    if(!$textarea.length) return false;

    autosize($textarea);
}
