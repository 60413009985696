$(function(){
    closeModal();
});

function closeModal() {
    $(document).on('keyup', function(e){
        if(e.which == 27){
            var $form = $('#mainForm');
            if($form.hasClass('active')) {
                $form.removeClass('active');
                $('body').removeClass('body_lock');
            }
        }
    });
}