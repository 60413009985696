$(function(){
    initDynamicLabel();
});

function initDynamicLabel() {
    var inputSelector = '[data-dynamic-inp]',
        labelClass = '[data-dynamic-label]',
        focusedClass = 'focused';
    $(document).on('focus', inputSelector, function() {
        var $this = $(this);
        console.log( $this.parent(labelClass));
        $this.parent(labelClass).addClass(focusedClass);
    });
    $(document).on('focusout', inputSelector, function() {
        var $this = $(this),
            length = $this.val().length;
        if (length > 0 || $this.attr('placeholder') !== undefined) {
            $this.parent(labelClass).addClass(focusedClass);
        } else {
            $this.parent(labelClass).removeClass(focusedClass);
        }
    });

    $(document).on('click', labelClass, function(e) {
        e.preventDefault();
        $(this).find(inputSelector).trigger('focus');
    });
    $(inputSelector).each(function(index, element) {
        var $this = $(this),
            length = $(element).val().length;
        if (length > 0 || element.autofocus || $this.attr('placeholder') !== undefined) {
            $this.parent(labelClass).addClass(focusedClass);
        } else {
            $this.parent(labelClass).removeClass(focusedClass);
        }
    });
}