"use strict";

class Nx {
    constructor() {
        this.$body = $('body');

        this.plugins = {
            container: $(document),
            initialize: []
        };

        this.init();
    }

    init(){
        this.bindsEvent();
    }

    bindsEvent(){
        $(document).on('DOMContentLoaded', () => {
            this.initPlugins();
        });
    }

    insertPreloader(obj, replace, theme) {
        let $obj = obj[0],
            additClass = theme ? 'nx-preloader_' + theme : '',
            preloaderEl = '[data-preloader]',
            hiddenClass = 'hidden',
            preloaderHtml =
                `<div class="nx-preloader ${additClass}" data-preloader><div class="nx-preloader__el"></div></div>`;

        if ($obj.querySelector(preloaderEl) !== null) {
            $obj.querySelector(preloaderEl).classList.remove(hiddenClass);
        } else {
            if (replace) {
                $obj.innerHTML = preloaderHtml;
            } else {
                $($obj).append(preloaderHtml);
            }
        }
    }

    initPlugins($container){
        if (!this.plugins.initialize.length || !this.plugins.container && !$container) return false;

        for (let i = 0; i < this.plugins.initialize.length; i++){
            this.plugins.initialize[i].init($container || this.plugins.container);
        }
    }

    addPlugin(newPlugin) {
        if (Array.isArray(newPlugin)) {
            for (let i = 0; i < newPlugin.length; i++) {
                this.plugins.initialize.push(newPlugin[i]);
            }
        } else {
            this.plugins.initialize.push(newPlugin);
        }
    }

    hidePreloader(obj, del) {
        let $obj = obj[0],
            preloaderEl = '[data-preloader]',
            hiddenClass = 'hidden';

        if ($obj.querySelector(preloaderEl)) {
            $obj.querySelector(preloaderEl).classList.add(hiddenClass);
            if (del) $obj.querySelector(preloaderEl).parentNode.removeChild($obj);
        }
    }

    getCookiePopup(name) {
        var matches = document.cookie.match(new RegExp(
            '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)',
        ));
        return matches ? decodeURIComponent(matches[1]) : '';
    }

    setCookie(name, value, options) {
        options = options || {};

        var expires = options.expires;

        if (typeof expires == 'number' && expires) {
            var d = new Date();
            d.setTime(d.getTime() + expires * 1000);
            expires = options.expires = d;
        }
        if (expires && expires.toUTCString) {
            options.expires = expires.toUTCString();
        }

        value = encodeURIComponent(value);

        var updatedCookie = name + '=' + value;

        for (var propName in options) {
            updatedCookie += '; ' + propName;
            var propValue = options[propName];
            if (propValue !== true) {
                updatedCookie += '=' + propValue;
            }
        }
        document.cookie = updatedCookie;
    }
}


export default Nx;