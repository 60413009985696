$(function(){
    // Отправка на сервер данных из формы и их проверка
    $(document).on('click', '[data-request]', sendRequest);
    // изменение значения для сопутствкющего инпута
    // check state of checkbox for send form
    $(document).on('change', '[data-agree-inp]', validateCheckbox);
    //Disabla button actions if it has disabled class or attr
    $(document).on('click', '.disabled, [disabled]', disableAction);
    //Send target data to data-field in modal
    $(document).on('click', '[data-send-to-modal]', sendDataToModal);
    //Send target data to data-field in modal
    $(document).on('click', '[data-ajax-redirect]', ajaxRedirect);

    $('body').on('keydown', "[data-num]", validateOnlyNum);
    $('body').on('keydown', "[data-float-num]", validateFloatNum);
    $('body').on('keydown keypress keyup', "[data-max]", validateMaxNum);
    $('body').on('keydown keypress keyup', '[data-max-inp-length]', validateMaxInpLength);
    $("[data-max-symbols]").unbind('keyup change input paste').bind('keyup change input paste', validateMaxSymbols);
});

function validateMaxInpLength(e) {
    var $self = $(this),
        max = $self.data('max-inp-length'),
        length = $self.val().split('').join('').length;
    if (length >= max) {
        if(e.which === 8 || e.which === 9) {
        } else {
            return false;
        }
    }
}
function isEmpty(object) {
    for (var prop in object) {
        if (object.hasOwnProperty(prop)) {
            return false;
        }
    }
    return true;
}
// Отправка на сервер данных из формы и их проверка
function sendRequest(e) {
    e.preventDefault();
    var $btn = $(this),
        home = $('body').data('home'),
        isCart = $btn.closest('[data-cart]'),
        type = $btn.data('request'),
        url = $btn.data('url'),
        lang = $('html').attr('lang') || 'ru',
        str  = $btn.closest('form').serialize();
    // Предотвращение повторной отправки, до ответа от сервера
    if( $btn.hasClass('sending') ) return false;
    $btn.addClass('sending');

    var data = str+'&ajax='+type;

    $.ajax({
        type    : "POST",
        url     : home + url,
        data    : data,
        success : function (response) {
            var redirect = response.redirect,
                fields = response.fields,
                errors = response;
            validate($btn, errors, redirect, fields);
            $btn.removeClass('sending');
        }
    });
    return false;
}

// Получение от сервера ошибок валидации и их обработка
function validate(object, errors, redirect, fields) {
    var $obj     	= $(object),
        type     	= $obj.data('request'),
        isCart    = $obj.closest('[data-cart]'),
        $form    	= isCart.length && type !== 'cart-send' ? $obj.closest('[data-fake-form]') : $obj.closest('form'),
        $formGroup  = $form.find('[data-form-group]'),
        $errorBox 	= $form.find('.form-error'),
        // $gCap       = $('.g-recaptcha'),
        $modal = $('.modal'),
        $modalOk = null,
        errorHtml = '<div class="form-error"></div>';

    if (typeof errors !== 'object' && errors) errors = $.parseJSON(errors);
    $formGroup.removeClass('error').addClass('success');
    $errorBox.remove();
    if ( !isEmpty(errors) && !redirect && !fields) {
        for (var inpName in errors) {
            if (errors.hasOwnProperty(inpName)) {
                var $target;
                switch (inpName){
                    default:
                        $target = $form.find('input[name="' + inpName + '"], textarea[name="' + inpName + '"]');
                        if ($target.length){
                            $target.closest('[data-form-group]').addClass('error').removeClass('success').append($(errorHtml).html(errors[inpName]));
                        }
                }
            }
        }
    } else {
        $obj.closest('form').find('[data-form-group]').removeClass('success');

        switch (type) {
            case 'callback':
                $modalOk = $('#modal-callback-ok');
                break;
            case 'feedback':
                $modalOk = $('#modal-feedback-ok');
                break;
            case 'subscribe':
                $modalOk = $('#modal-ok');
                $modalOk.removeClass('hidden');
                break;
            default:
                $modalOk = $('#modal-ok');
        }

        if($obj.hasClass('js-modal-vac__btn')) {
            $('.modal-vac').removeClass('show').hide();
            $('.modal-success').addClass('active');
            setTimeout(function(){
                $('.modal-backdrop').remove();
            }, 200);
        }

        if ($modalOk){
            $modal.modal('hide');
            setTimeout(function() {
                $modalOk.modal('show');
            }, 100);
        }

        $form.find('.js-file-list').html('');
        if (type !== 'savechanges' && type !== 'profile-update' && type !== 'calc' ){
            $form.find('input[type!=hidden], textarea').val('');
        }

        console.log($('.files__list'));
        $('.files__list').html('').addClass('hidden');

    }
}

//check state of checkbox for send form
function validateCheckbox(e) {
    e.preventDefault();
    var $agreeInp = $(this),
        $agreeBox = $agreeInp.closest('form'),
        $agreeBtn = $agreeBox.find('[data-agree-btn]'),
        hasHref = $agreeBtn.is('[href]');
    if (!hasHref) {
        if ( $agreeInp.prop('checked') ) {
            $agreeBtn.prop('disabled', false);
        }else {
            $agreeBtn.prop('disabled', true);
        }
    }else{
        // var dataHref = $agreeBtn.data('save-href');
        if ( $agreeInp.prop('checked') ) {
            $agreeBtn.removeClass('disabled');
            // $agreeBtn.attr('href', dataHref);
        }else {
            $agreeBtn.addClass('disabled');
            // $agreeBtn.attr('href', '');
        }
    }
}

//Disabla button actions if it has disabled class or attr
function disableAction(e) {
    e.preventDefault();
    e.stopPropagation();
    return false;
}
$('#modal-signup-ok').on('hidden.bs.modal	', function() {
    var $modalRedirect = $('[data-modal-redirect]');
    if($modalRedirect.length) {
        var url = $modalRedirect.data('modal-redirect');
        window.location.replace(url);
    } else {
        window.location.reload();
    }
});

$('.js-close-modal').on('click', function (e) {
    console.log('click');
    e.preventDefault();

    window.scrollTo(0,0);
})

//Ввод только цифр
function validateOnlyNum(e){
    if(e.which !== 8 &&
        e.which !== 9 &&
        e.which !== 116 &&
        e.which !== 46 &&
        (e.which < 48 || e.which > 57) &&
        (e.which < 96 || e.which > 105) &&
        (e.which < 37 || e.which > 40)){
        e.preventDefault();
        return false;
    }
}

//Ввод только цифр
function validateFloatNum(e){
    console.log(e.which);
    if(e.which !== 8 &&
        e.which !== 9 &&
        e.which !== 116 &&
        e.which !== 46 &&
        e.which !== 110 &&
        e.which !== 188 &&
        e.which !== 190 &&
        e.which !== 191 &&
        (e.which < 48 || e.which > 57) &&
        (e.which < 96 || e.which > 105) &&
        (e.which < 37 || e.which > 40)){
        e.preventDefault();
        return false;
    }
}
//Проверка на максимальное число
function validateMaxNum(){
    var $self = $(this),
        val = Number($self.val()),
        max = Number($self.data('max-num'));

    if (val >= max){
        $self.val(max);
    }
}
//Проверка на максимальное число
function validateMaxSymbols(){
    var $self = $(this),
        val = $self.val(),
        valLength = val.length,
        maxCount = $self.data('max-symbols'),
        $maxCountBox = $self.closest('[data-form-group]').find('[data-max-length]');

    if(valLength > maxCount){
        $self.val($self.val().substring(0, maxCount));
    }else{
        $maxCountBox.text(valLength);
    }
}

//Передача значения из формы в модалку, с очищением изначальной формы
function sendDataToModal() {
    var $sendBtn = $(this),
        $target = $sendBtn.closest('[data-form]').find('[data-form-target]'),
        targetVal = $target.val(),
        $destination = $('[data-destination]');

    $target.val("");
    $destination.val(targetVal);
}

function ajaxRedirect() {
    var $this = $(this),
        lang = $('html').attr('lang') || 'ru',
        url = $this.data('ajax-redirect');

    $.ajax({
        type    : "POST",
        url     : '/' + lang + url,
        success : function (response) {
            window.location.replace(response.request);
        }
    });
}