$(function(){
    initVselect();
});

function initVselect() {
    document.addEventListener('click', function(e){
        let $allSearches = document.querySelectorAll('.v-select-active'),
            $closestDropdownOpen = e.target.closest('.v-select-active');

        if (!$closestDropdownOpen && $allSearches.length) {
            $allSearches.forEach(($s) => {
                $s.classList.remove('v-select-active')
            });
        }
    });

    $(document).on('click', '[data-v-select-link]', function(e) {
        e.preventDefault();
        var $self = $(this),
            $select = $self.closest('[data-v-select]');

        $select.toggleClass('v-select-active');
    });

    $(document).on('click', '[data-v-select-item]', function(e) {
        e.preventDefault();
        var $self = $(this),
            val = $self.data('v-select-item'),
            text = $self.text(),
            $select = $self.closest('[data-v-select]'),
            $linkList = $select.find('[data-v-select-item]'),
            $title = $select.find('[data-v-select-title]'),
            url = $self.closest('[data-select-url]').data('select-url'),
            data = {
                region: val,
                id: 0
            };

        $select.removeClass('v-select-active');
        $linkList.removeClass('active');
        $self.addClass('active');
        $title.text(text);
        $title.data('v-select-title', val);

        if ($self.hasClass('sending')) return false;
        $self.addClass('sending');

        $.ajax({
            type: "POST",
            url: url,
            data: data,
            success: (res) => {
                $self.removeClass('sending');
                let resp = JSON.parse(res);
                $('[data-career-cnt]').text(resp.total);
                $('[data-v-url]').html(resp.html_filter);
                $('[data-load-content]').html(resp.html);
                var $showMoreBtn = $('[data-filter-show-more]');
                if(resp.isEnd) {
                    $showMoreBtn.addClass('hidden')
                } else {
                    $showMoreBtn.removeClass('hidden')
                }
                if($showMoreBtn.length) {
                    $showMoreBtn[0].dataset.showMore = 2;
                }

                $('.js-hor-scroll').each(function () {
                    checkScrollArrow($(this));
                });

            },
            error: (qXHR, textStatus, errorThrown) => {
                $self.removeClass('sending');
                console.log(qXHR, textStatus, errorThrown);
            }
        });
    });
}

function checkScrollArrow(block) {
    if (block.hasClass('js-hor-scroll--arrow')) {
        var $arrowContainer = block.closest('.js-arrow-container'),
            $boxContent = $arrowContainer.find('.js-pf-filter__list'),
            $boxArrow = $arrowContainer.find('.js-pf-filter__arrow');

        if (Math.ceil($boxContent.width()) > Math.ceil(block.width())) {
            $boxArrow.addClass('active');
        } else {
            $boxArrow.removeClass('active');
        }
    }
}