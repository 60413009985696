import "core-js/stable";
import "regenerator-runtime/runtime";
import elementClosest  from "element-closest";
import objectFitImages from 'object-fit-images';

objectFitImages();
elementClosest(window);
import Nx from './modules/Nx';
import 'bootstrap/js/dist/modal';

import './plugins/ListSlider';
// import './plugins/likely';

import './modules/ShowMore';
import './modules/Vlink';
import './modules/Cookies';
import './modules/Vselect';
import './modules/BxPanelFix';
import './modules/DynamicLabel';
import './modules/AutoSizeTarea';
import './modules/AttachDragger';
import './modules/LoadSvgSprite';
import './modules/FilterNews';
import './modules/ToggleFilter';
import './modules/ShowVideo';
import './modules/CheckWindowSize';
import './modules/Request';
import HeaderLock from './modules/HeaderLock';
//import './modules/HmenuToggle';
//import './modules/Swiper';
import './modules/CloseModal';
import './modules/TabsSlider';

import AOSInit from './plugins/AOSInit';
import UploadField from './plugins/UploadField';
import ListSlider from "./plugins/ListSlider";

function afterReveal (el) {
    el.addEventListener('animationstart', function () {
        el.classList.add('animation-done')
    });
}

$.nx = new Nx();
$.nx.addPlugin([

    // new AOSInit(),
    // new UploadField(),
    // new WOW({
    //     animateClass: 'animated',
    //     callback: afterReveal
    // })
]);

// new HeaderLock();

new ListSlider()