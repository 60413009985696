$(function(){
    showMore();
    closeModal();
});

function closeModal() {
    document.addEventListener('keyup', function (e) {
        if(e.keyCode === 27) {
            if($('.modal-success').length) {
                if($('body').hasClass('body_lock'));
                $('.modal-success').removeClass('active');
                $('body').removeClass('body_lock');
            }
        }
    });
    $('[data-modal-close]').on('click', function(e) {
        e.preventDefault();
        var $self = $(this),
            $modal = $self.closest('.modal-success');
        $modal.removeClass('active');
        $('body').removeClass('body_lock');
        $('body').removeClass('modal-open');
    });
    $('[data-modal-vac]').on('click', function(e) {
        e.preventDefault();
        var $self = $(this),
            id = $self.data('modal-vac'),
            $modal = $($(this).attr('href')),
            $inp = $modal.find('[data-modal-id]');
        $inp.val(id);
        $modal.removeClass('hidden');
        setTimeout(function() {
            $modal.modal('show');
        }, 100);
    });
}

function showMore() {
    $('[data-show-more]').on('click', function(e) {
        e.preventDefault();
        let $target = $(this),
            pageVal = $(this)[0].dataset.showMore,
            isNews = $target.data('show-more-news') === 'news',
            isVac = $('[data-filter-show-more]').length,
            $contentBox = $('[data-load-content]'),
            data = {
                PAGEN_1: pageVal,
                ajax: 'show_more'
            },
            url = $target.data(`show-more-url`);

        if(isNews) {
            data.id = $('[data-filter-id].active-filter').data('filter-id');
        }
        if(isVac) {
            data.region = $('[data-v-select-title]').data('v-select-title');
            data.id = $('.v-filter__link.active').data('v-link');
        }
        if($target.data('show-more-filter') == 1) {
            data.region = $('[data-v-select-title]').data('v-select-title');
        }
        if ($target.hasClass('sending')) return false;
        $target.addClass('sending');
        $target.addClass('disabled');

        $.nx.insertPreloader($contentBox, false, 'btn');
        $.ajax({
            type: "POST",
            url: url,
            data: data,
            success: (res) => {
                $target[0].classList.remove('sending');
                let resp = JSON.parse(res);
                $contentBox.append(resp.html);

                $.nx.hidePreloader($contentBox);
                $target[0].classList.remove('disabled');


                let cnt = Number(data.PAGEN_1) + 1;

                $('[data-show-more]')[0].dataset.showMore = cnt;
                if (resp.isEnd) {
                    $('[data-show-more]')[0].classList.add('hidden');
                }
            },
            error: (qXHR, textStatus, errorThrown) => {
                $target.removeClass('sending');
                $.nx.hidePreloader($contentBox);
                console.log(qXHR, textStatus, errorThrown);
            }
        });
    });
}