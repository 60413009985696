$(function(){
    initTabsSlider();
});

function initTabsSlider() {
    let $tabsSlider = $('[data-tabs-slider]'),
        $next = $tabsSlider.find(`[data-tabs-slider-next]`)[0];
        //$prev = $tabsSlider.find(`[data-tabs-slider-prev]`)[0];

    if (!$tabsSlider.length) return false;

    let tabsSlider = new Swiper($tabsSlider[0], {
        slidesPerView: 'auto',
        loop: false,
        autoHeight: false,
        navigation: {
            nextEl: $next
        },
        on: {
            resize: function() {
                console.log($(this).activeIndex)
            }
        },
        scrollbar: {
            el: '[data-tabs-slider-scrollbar]',
            hide: false
        },
    });

    var $tabLinks = document.querySelectorAll('[data-tab-link]'),
        $tabSlide = document.querySelectorAll('[data-tab-slide]'),
        $tabsArrow = document.querySelectorAll('[data-tabs-slider-arrow]'),
        id = null;

    $tabsArrow.forEach(el => {
        el.addEventListener('click', (e) => {
           var $self = $(e.currentTarget);

            $tabLinks.forEach(el => {
                el.classList.remove('active');
            });
            var index = tabsSlider.activeIndex;
            var length = $('.tabs-slider__el').length;
            if(index + 2 >= length) {
                $('[data-tabs-slider-arrow]').addClass('hidden');
                $('[data-tab-slider]').addClass('no-before');
            } else {
                $('[data-tabs-slider-arrow]').removeClass('hidden');
                $('[data-tab-slider]').removeClass('no-before');
            }

            document.querySelector(`[data-tab-link="${index}"]`).classList.add('active');
        });
    });

    $tabLinks.forEach(el => {
        el.addEventListener('click', (e) => {
            e.preventDefault();
            var $self = e.currentTarget;
            id = $self.getAttribute('data-tab-link');

            $tabLinks.forEach(el => {
                el.classList.remove('active');
            });
            $self.classList.add('active');

            $tabSlide.forEach(el => {
                el.classList.remove('active');
            });

            document.querySelector(`[data-tab-slide="${id}"]`).classList.add('active');
            tabsSlider.slideTo(id);
            var length = $('.tabs-slider__el').length;

            if(Number(id) + 2 >= length) {
                $('[data-tabs-slider-arrow]').addClass('hidden');
                $('[data-tab-slider]').addClass('no-before');
            } else {
                $('[data-tabs-slider-arrow]').removeClass('hidden');
                $('[data-tab-slider]').removeClass('no-before');
            }
        });
    });
}