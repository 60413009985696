$(function(){
    bxPanelFix();
});
function bxPanelFix() {
    var $bxPanel = $('#bx-panel');

    if (!$bxPanel.length) return false;

    var $bxFix = $('body'),
        bxPanelH = 0,
        hh = 0,
        $wrapper = $('[data-bx-wrapper]'),
        $header = $('[data-bx-header]'),
        $togglers = $bxPanel.find('#bx-panel-hider, #bx-panel-expander, .adm-warning-close');

    var addOffsets = function(){
            hh = parseInt($header.outerHeight());
            bxPanelH = parseInt($bxPanel.outerHeight());
            var $body = $('body');
            $body.css('position', 'relative');
            $body.css('top', bxPanelH);
            $body.addClass('bx-panel');
            return false;
        },
        resizeWindow = function () {
            return $(window).trigger('resize');
        };
    addOffsets();

    $togglers.on('click', resizeWindow);

    $(window).on('resize', addOffsets);
}
