$(function(){
    cookiePopup();
});

function cookiePopup() {

    var $cookieBanner = document.querySelector('[data-cookie-banner]');
    if(!$.nx.getCookiePopup('cookie-warning')) {
        $cookieBanner.classList.remove('hidden');
    }

    if ($cookieBanner) {
        document.querySelector('[data-cookie-btn]').addEventListener('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            var  currentDomain = location.host;
            var date = new Date(),
                options = {'path': '/'};
            options.expires = date.setDate(date.getDate() + 1);
            $.nx.setCookie('cookie-warning',true, options);
            $cookieBanner.classList.add('hidden');
        });
    }
}