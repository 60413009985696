$(function(){
    showVideo();
});

function showVideo() {
    $(document).on('click', '[data-video-link]', function(e) {
        e.preventDefault();
        let $self = e.currentTarget,
            videoID = $self.getAttribute(`data-video-id`),
            iframeHtml = `<iframe src="https://www.youtube.com/embed/${videoID}?autoplay=1" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

        let $parent = $self.closest(`[data-video-content]`);
        $parent.innerHTML = '';
        $parent.insertAdjacentHTML('beforeend',iframeHtml);
    });
}