$(function(){
   filterToggle();
});

/*
function filterToggle() {
    let $links = $('[data-filter-link]');
    $links.on('mouseenter', toggleFilter);

    function toggleFilter() {
        const $self = $(this);
        $self.trigger('click');
    }
}*/


function filterToggle() {
    let $linksBottom = $('[data-subfilter-btn]');

    $linksBottom.on('click', function() {
       let $self = $(this),
           id = $self.data('subfilter-btn');

       $(`[data-filter-top-btn="${id}"]`).addClass('pf-filter-btn-active');
    });
}