'use strict';
/**
 * Прокрутка надписи при скролле страницы
 * @param minWidth - ширина окна, со которой на чинает работать скрипт
 * @param offset - отступ от верха окна, при котором вешается класс "в движении"
 * @param moved - имя класса "в движении"
 * @param el - дата атрибут хедера
 */

class HeaderLock {
    constructor(element, options) {
        this.options = {
            documentHeight: document.documentElement.scrollHeight,
            minWidth: 768, //Заглушка, чтобы работало и на мобиле
            offset: 200
        };

        this.className = {
            moved: 'in-move'
        };

        this.dataName = {
            el: 'header',
        };

        this.$el = element || document.querySelector(`[data-${this.dataName.el}]`);

        $.extend(true, this, this, options);

        this.init();
    }

    //Method for run all class methods
    init(){
        this.bindEvents();
    }

    //Method for all events
    bindEvents(){
        if($(window).width() >= 768) {
            document.addEventListener('DOMContentLoaded', this.moveOnPageScroll.bind(this));
            window.addEventListener('scroll', this.moveOnPageScroll.bind(this));
        }
    }

    /**
     * Moves text on scroll
     * @param e - event
     */
    moveOnPageScroll(e){
        if (window.innerWidth < this.options.minWidth){
            this.$el.classList.remove(`${this.className.moved}`);
            return false;
        }

        const elScrollTop = document.documentElement.scrollTop;

        if (elScrollTop > this.options.offset){
            this.$el.classList.add(`${this.className.moved}`);
        } else  {
            this.$el.classList.remove(`${this.className.moved}`);
        }
    }

}

export default HeaderLock;