$(function() {
    loadSvgSprite();
});

function loadSvgSprite() {
    //let svgUrl = `${$('body').data('home')}/assets/svg.min.html`;
    let svgUrl = '/local/html/assets/svg.min.html';

    $.get(svgUrl)
    .done((data) => {
        if (!data) return false;
        let div = document.createElement('div');
        div.classList.add('svg-icons');
        div.innerHTML = String(data);
        document.body.appendChild(div);
    })
    .fail((err) => {
        console.log(`Svg file load error message: ${err.statusText}`, err);
    });
}