$(function(){
    vLink();
});

function vLink() {
    $(document).on('click', '[data-v-link]', function(e) {
        e.preventDefault();
        let $target = $(this),
            id = $target.data('v-link'),
            $contentBox = $('[data-load-content]'),
            url = $('[data-v-url]').data('v-url'),
            region = $('[data-v-select-title]').data('v-select-title'),
            data = {
                id: id,
                region: region,
                ajax: 'show_more'
            },
            $targetParent = $target.closest('.v-filter__item');

        $('.v-filter__item').removeClass('pf-filter-btn-active');
        $('[data-v-link]').removeClass('active');
        $target.addClass('active');
        $targetParent.addClass('pf-filter-btn-active');
        if ($target.hasClass('sending')) return false;
        $target.addClass('sending');
        $target.addClass('disabled');

        $.nx.insertPreloader($contentBox, false, 'btn');
        $.ajax({
            type: "POST",
            url: url,
            data: data,
            success: (res) => {
                console.log(res);
                $target[0].classList.remove('sending');
                let resp = JSON.parse(res);
                $contentBox.html(resp.html);
                var $filter = $('[data-show-more-filter]');
                if(resp.isEnd) {
                    $filter.addClass('hidden');
                } else {
                    $filter.removeClass('hidden');
                }
                if($filter.length) {
                    $filter[0].dataset.showMore = 2;
                }
                $.nx.hidePreloader($contentBox);
                $target[0].classList.remove('disabled');
            },
            error: (qXHR, textStatus, errorThrown) => {
                $target.removeClass('sending');
                $.nx.hidePreloader($contentBox);
                console.log(qXHR, textStatus, errorThrown);
            }
        });
    });
}